@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Jost&family=Lato&family=Montserrat:wght@400;700&family=Oleo+Script&family=Open+Sans:wght@600&family=Poppins:ital,wght@0,100;0,300;0,400;0,500;0,600;1,400&display=swap');

.drop-down {
  position: absolute;
  margin-left: 5%;

}

.drop-down-item {
  position: relative;
  right: 10rem;
  top: 8rem;
}

.Main-contents {
  padding: 2rem;
}
.download-invoice{
  display: flex;
  align-items: center;
  gap:1rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 600 !important;
  font-size: 20px;
  color: blue;

}
.Cards {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.card {
  display: flex;
  gap: 2rem;
  align-items: center;
  width: auto;
  padding: 10px;
  border: 1px solid silver;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 15px;
  width: 35rem;
  height: 8rem;
}

#total {
  letter-spacing: 5px;
}

.card:nth-child(1) {
  gap: 4rem;
}

.card-details>p:nth-child(1) {
  font-size: 25px;
}

.card-details>p:nth-child(2) {
  font-size: 20px;
}

.Cart-page {
  margin: 2rem;
  border: 1px solid silver;
  border-radius: 10px;
  background-image: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
  overflow-y: scroll;
  height: 80vh;
}

.Icons {
  width: 4rem;
  height: 4rem;
  /* margin:10px; */
  border-radius: 50%;
  background-color: lightcyan;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-details {
  text-align: center;
  font-family: 'Poppins', sans-serif;

}

.card-details>p {
  margin: 5px;
  line-height: 25px;
}

.revenue-filter>button {
  padding: 10px;
  background-color: blue;
  color: white;
  border-radius: 8px;
  font-family: 'Poppins', sans-serif;
  margin: 10px
}

.revenue-filter {
  display: flex;
  margin: 2rem;
  flex-direction: column;
  align-items: flex-end;
}

select {
  border-radius: 8px;
}

.date-picker {
  border: 1px solid silver;

  position: absolute;
  top: 30%;
  /* left: 0;
    right: 0; */
  bottom: 0;
}

html,
body {
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.search-box {
  display: flex;
  align-items: center;
  border: 1px silver solid;
  background-color: white;
  width: fit-content;
  height: 45px;
  border-radius: 20px;
}

.search-box>.MuiSvgIcon-root {
  color: gray;
  padding: 10px;
  font-size: 45px;
}

.search-box>input {
  border: none;
  outline-width: 0;
  padding: 3px;
  margin-right: 10px;
  margin-left: 10px;
}

.search-box>input:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

.Search-bar {
  display: flex;
  margin-top: 50px;
  margin: 1rem;
  flex-direction: column;
  align-items: flex-end;
}

.Table {
  border-radius: 15px;
  margin-top: 30px;
  border: 1px silver solid;
  margin: '10px 10px';
  max-width: 950
}

.Table-header {
  color: white !important;
  font-family: 'Poppins', sans-serif;
  font-weight: 600 !important;
  background-color: #303f9f !important;
}

.Order_title {
  font-family: 'Poppins', sans-serif;
  font-size: 30px;
  font-weight: 600 !important;
  margin: 1rem;
}

.animation {
  width: 30rem;
}

.logo-img {
  width: 20rem;
}

.page-left-side {
  width: 50%;
  margin: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.Login-page {
  display: flex;
  background-image: linear-gradient(to top, #cfd9df 0%, #e2ebf0 100%);
  height: 100vh;
}

.page-right-side {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#Signin {
  width: 30rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  gap: 1rem;
  align-items: center;
  border: 1px solid silver !important;
  border-radius: 20px;
  padding: 2rem;
}

.Login-btn {
  margin: 20rem;
}

#Signin>p {
  font-family: 'Poppins', sans-serif;
  font-size: 30px;
  font-weight: 600 !important;
  letter-spacing: 3px;
  margin: 1rem;
}

#outlined-basic:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

#outlined-adornment-password:focus {
  outline: none !important;
  outline-width: 0 !important;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

.Customer-details {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row: auto;
  padding: 2rem;
}

.Customer-details>p {
  margin: 1rem;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 600 !important;
}

.Customer-details>p>span {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 400 !important;
}

.Cart-items {
  margin: 1rem;
}

.Cart-items>p {
  font-family: 'Poppins', sans-serif;
  margin: 1rem;
  font-size: 20px;
  font-weight: 600 !important;
}

.Product-details {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.Product-details>img {
  width: 5rem;
  border-radius: 8px;
}

.Product-details>div>p {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 600 !important;
}

.Action_buttons {
  display: flex;
  justify-content: space-evenly;
  padding: 1rem;
}

.btn {
  padding: 1rem;
}

.Order_Status {
  display: flex;
  margin: 10px;
  flex-direction: column;
  align-items: flex-end;
}

.Order-status-element {
  display: flex;
  gap: 20px;
  padding: 10px;
  font-size: 18px;
}

.Order-status-element>p {
  font-family: 'Poppins', sans-serif;
  font-weight: 600 !important;
}

.Order-status-element>p>span {
  font-family: 'Poppins', sans-serif;
  font-weight: 400 !important;
}

.Sales_report {
  margin: 1rem;
  padding: 1rem;
}

.Sales_report>p {
  font-family: 'Poppins', sans-serif;
  font-weight: 600 !important;
  font-size: 20px;
}

.charts {
  width: auto;
  margin-top: 2rem;
  display: flex;
  gap: 2rem;
  align-items: center;
}

.chart:nth-child(1) {
  width: 80rem;
  padding: 2rem;
  border: 1px solid silver;
  border-radius: 10px;
}

.chart:nth-child(2) {
  width: 40rem;
  padding: 2rem;
  border: 1px solid silver;
  border-radius: 10px;
}

#file-upload {
  /* display: none; */
}

.upload_report {
  display: flex;
  justify-content: space-evenly;
  margin: 1rem;
  align-items: center;
}

#DialogTitle {
  font-family: 'Poppins', sans-serif;
  font-weight: 600 !important;
  font-size: 20px;
}

.dialog {
  border: 1px solid silver !important;
  border-radius: 8px !important;
  padding: 1rem !important;
}

@media only screen and (max-width:1024px) {
  .Cards {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    place-items:center;
  }

  .card {
    width: 20rem;
  }

  .charts {
    flex-direction: column;
  }

  .Product-details {
    flex-direction: column;
  }

  .Customer-details {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-row: auto;
    grid-gap: 2rem, 1rem;
    padding: 0rem;
  }

  .Action_buttons {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-row:auto;
    gap:1rem;
    padding:2rem;
    grid-gap:2rem,1rem;
  }

  .Login-page {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }

  .upload_report {
    flex-direction: row-reverse;
  }
  .chart:nth-child(1) {
    width:30rem;
    padding: 2rem;
    border: 1px solid silver;
    border-radius: 10px;
  }

  .chart:nth-child(2) {
    width: 25rem;
    padding: 2rem;
    border: 1px solid silver;
    border-radius: 10px;
  }
}

@media only screen and (max-width:640px) {
  .revenue-filter {
    margin: 0px;
  }

  .Cards {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin: 0rem;
  }

  .card {
    width: 20rem;
  }

  .chart:nth-child(1) {
    width: 25rem;
    padding: 2rem;
    border: 1px solid silver;
    border-radius: 10px;
  }

  .chart:nth-child(2) {
    width: 20rem;
    padding: 2rem;
    border: 1px solid silver;
    border-radius: 10px;
  }

  .Customer-details {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-row: auto;
    padding: 0rem;
  }

  .Product-details {
    flex-direction: column;
  }

  .Cart-page {
    width: 20rem;
    padding: 0rem;
    margin: 10px;
  }

  .upload_report {
    flex-direction: column-reverse;
    gap: 2rem;
  }
}